import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import generateUid from './generateUid'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
}

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
} else {
  firebase.app()
}

const db = firebase.firestore()

export const CouponStatus = {
  AVAILABLE: 'available',
  RESALE: 'resale',
  CLAIMED: 'claimed',
  REDEEMED: 'redeemed',
}

export const Role = {
  ADMIN: 'admin',
  MERCHANT: 'staff',
  STAFF: 'staff'
}

export const ErrorMessage = {
  ADDRESS_NOT_MATCH: 'Address ไม่ถูกต้อง',
  CODE_INCORRECT: 'Code ไม่ถูกต้อง',
  CODE_ALREADY_REDEEMED: 'Code Redeemed ไปแล้ว',
  SHOP_NOT_MATCH: 'ร้านค้าไม่ถูกต้อง',
}

export const firebaseLogin = (email, password) => {
  return firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then((userCredential) => userCredential.user)
}

export const firebaseLogout = () => {
  return firebase.auth().signOut()
}

export const checkAuthen = () => {
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        resolve(user)
      } else {
        reject()
      }
    })
  })
}

export const getUserId = () => {
  return firebase.auth() && firebase.auth().currentUser
}

export const getAccountRole = (uid) => {
  return db
    .collection('accounts')
    .doc(uid)
    .get()
    .then((data) => data.data().role)
}

export const getAccount = (uid) => {
  return db
    .collection('accounts')
    .doc(uid)
    .get()
    .then((shopData) => {
      return shopData.data()
    })
}

// ===================== Booking order log =====================
export const getOrdersCheckIn = () => {
  return db
    .collection('ordersCheckIn')
    .get()
    .then((data) => {
      const result = []
      data.forEach(async (doc) => {
        const orderData = doc.data()
        if (orderData.checkInAt) orderData.checkInAt = new Date(orderData.checkInAt.toDate())
        result.push({ ...orderData, bookingId: orderData.order.id })
      })
      
      const sortData = result
        .sort((a, b) => (new Date(`${b.departureDate} ${b.departureTime}`) - new Date(`${a.departureDate} ${a.departureTime}`)))
        // .sort((a, b) => a.position - b.position)
      return sortData
    })
}
// ===================== Booking order =====================
const getMemberOrders = () => {
  return db
    .collection('userOrders')
    .get()
    .then((data) => {
      const result = []
      data.forEach((doc) => {
        result.push({ ...doc.data(), fromCollectionDB: 'userOrders' })
      })
      return result
    })
}
const getGuestOrders = () => {
  return db
    .collection('guestOrders')
    .get()
    .then((data) => {
      const result = []
      data.forEach((doc) => {
        result.push({ ...doc.data(), fromCollectionDB: 'guestOrders' })
      })
      return result
    })
}
export const Booking = {
  getBookingOrders: async () => {
    const memberOrders = await getMemberOrders()
    const guestrOrders = await getGuestOrders()
    const mergeOrders = [...memberOrders, ...guestrOrders]
    return mergeOrders
  },
  checkIn: async (bookingData, user, lastPosition) => {
    const now = new Date()

    const dataToSend = ({
      user: user.email, isCheckIn: true, checkInAt: now
    })

    if (lastPosition) await bookingData?.order?.update({ status: 'REDEEMED'})

    return db
      .collection('ordersCheckIn')
      .doc(bookingData.id)
      .update({ ...dataToSend })
      .then(() => console.log('check in success'))
  },
  checkInChangeStatus: async (bookingData) => {
    const now = new Date()

    await bookingData?.order?.update({ status: 'PENDING'})

    return db
      .collection('ordersCheckIn')
      .doc(bookingData.id)
      .update({ isCheckIn: bookingData.isCheckIn, user: '', updatedAt: now })
      .then(() => console.log('change status success'))
  },
}

// ===================== VOUCHER =====================

export const Voucher = {
  getShopVouchers: (shopId) => {
    return db
      .collection('vouchers')
      .where('shopId', '==', shopId)
      .get()
      .then((querySnapshot) => querySnapshot.docs.map((data) => data.data()))
  },
  subscribeVoucherCode: () => {
    return db.collection('codes')
  },
  redeem: async (code, addressKey, shopId) => {
    const now = new Date()

    const resultCheckAddress = await checkAddress(code, addressKey)
    if (resultCheckAddress === false) {
      throw Error(ErrorMessage.ADDRESS_NOT_MATCH)
    }

    const resultVoucherId = await getVoucherIdFromCode(code)
    if (resultVoucherId.length <= 0) {
      throw Error(ErrorMessage.CODE_INCORRECT)
    }

    const resultShopId = await checkVoucherShopId(resultVoucherId[0], shopId.id)

    if (resultShopId === false) {
      throw Error(ErrorMessage.SHOP_NOT_MATCH)
    }

    const resultCheckStatus = await checkVoucherStatus(code)
    if (resultCheckStatus === true) {
      throw Error(ErrorMessage.CODE_ALREADY_REDEEMED)
    }

    const codes = await checkVoucherRedeem(code)
    if (codes.length <= 0) {
      throw Error(ErrorMessage.CODE_INCORRECT)
    }

    const updateCode = codes[0]

    const codeDetail = {
      ...updateCode,
      redeemedAt: now,
    }

    return db
      .collection('codes')
      .doc(codeDetail.id)
      .update(codeDetail)
      .then(() => codeDetail)
  },
  getVoucherName: (voucherId) => {
    return db
      .collection('vouchers')
      .where('id', '==', voucherId)
      .get()
      .then((querySnapshot) => querySnapshot.docs.map((data) => data.data().title))
  },
}

const checkAddress = (codeSplit, addressSplit) => {
  return db
    .collection('codes')
    .where('code', '==', codeSplit)
    .get()
    .then((data) => {
      if (data.docs.length > 0) {
        const address = data.docs[0].data().claimedBy
        address.substr(address.length - 6, address.length - 4)
        if (addressSplit === address.substr(-6, 2)) {
          return true
        }
      }
      return false
    })
    .catch(() => null)
}
const getVoucherIdFromCode = (code) => {
  return db
    .collection('codes')
    .where('code', '==', code)
    .get()
    .then((data) => {
      const result = []
      data.forEach((doc) => {
        result.push(doc.data().voucherId)
      })
      return result
    })
}
const checkVoucherShopId = (voucherId, shopId) => {
  return db
    .collection('vouchers')
    .where('id', '==', voucherId)
    .get()
    .then((data) => {
      if (data.docs.length > 0) {
        const dataShopId = data.docs[0].data().shopId
        if (dataShopId === shopId) {
          return true
        }
      }
      return false
    })
    .catch(() => null)
}
const checkVoucherStatus = (code) => {
  return db
    .collection('codes')
    .where('code', '==', code)
    .get()
    .then((checkStatusRedeem) => {
      if (checkStatusRedeem.docs.length > 0) {
        const { status } = checkStatusRedeem.docs[0].data()
        if (status === CouponStatus.REDEEMED) {
          return true
        }
      }
      return false
    })
    .catch(() => null)
}
const checkVoucherRedeem = (codeSplit) => {
  const now = new Date()

  return db
    .collection('codes')
    .where('code', '==', codeSplit)
    .where('status', '==', CouponStatus.CLAIMED)
    .where('expiredAt', '>=', now)
    .get()
    .then((checkdataRedeem) => {
      const result = []
      checkdataRedeem.forEach((doc) => {
        result.push({
          ...doc.data(),
          status: CouponStatus.REDEEMED,
        })
      })
      return result
    })
}
// ===================== VOUCHER =====================


// ===================== COUPON =====================
export const Coupon = {
  getCoupons: () => {
    return db
      .collection('coupons')
      .get()
      .then((querySnapshot) => querySnapshot.docs.map((data) => data.data()))
  },
  getShopCoupons: (shopId) => {
    return db
      .collection('coupons')
      .where('shopId', '==', shopId)
      .get()
      .then((querySnapshot) => querySnapshot.docs.map((data) => data.data()))
  },
  subscribeCouponCode: () => {
    return db.collection('couponCodes')
  },

  redeem: async (code, shopId) => {
    const now = new Date()

    const resultCouponId = await getCouponIdFromCode(code)
    if (resultCouponId.length <= 0) {
      throw Error(ErrorMessage.CODE_INCORRECT)
    }

    const resultShopId = await checkCouponAvailable(resultCouponId[0], shopId.id)

    if (resultShopId === false) {
      throw Error(ErrorMessage.CODE_INCORRECT)
    }

    const resultCheckStatus = await checkCouponCodeStatus(code)
    if (resultCheckStatus) {
      throw Error(ErrorMessage.CODE_ALREADY_REDEEMED)
    }

    const codes = await checkCouponCodeRedeem(code)
    if (codes.length <= 0) {
      throw Error(ErrorMessage.CODE_INCORRECT)
    }

    const codeDetail = {
      ...codes[0],
      redeemedAt: now,
    }

    return db
      .collection('couponCodes')
      .doc(codeDetail.id)
      .update(codeDetail)
      .then(() => codeDetail)
    // return new Promise((resolve) => resolve())
  },
  getCouponName: (couponId) => {
    return db
      .collection('coupons')
      .where('id', '==', couponId)
      .get()
      .then((querySnapshot) => querySnapshot.docs.map((data) => data.data().title))
  },
  addCoupon: (coupon) => {
    const id = generateUid(10)
    const doc = {
      ...coupon,
      id,
      createdAt: new Date(),
    }
    return db
      .collection('coupons')
      .doc(id)
      .set(doc)
      .then(() => doc)
  },
  addCouponCodeBatch : (couponId, quota) => {
    console.log("couponId", couponId)
    const batch = db.batch()
  
    for (let i = 0; i < quota; i += 1) {
      // await FirestoreService.addVoucherCode("25c888d6b55f093a2cce")
      const id = generateUid(10)
      const code = generateUid(3)
      const ref = db.collection('couponCodes').doc(id)
      batch.set(ref, {
        id,
        code: `cou${code}`,
        couponId,
        status: CouponStatus.AVAILABLE,
      })
    }

    return batch.commit()
  },
  addCouponFlashsale : (coupon) => {
    const doc = {...coupon}
    return db
      .collection('coupons')
      .doc(coupon.id)
      .set(doc)
      .then(() => doc)
  },
  getCouponById: (couponId) => {
    return db
      .collection('coupons')
      .where('id', '==', couponId)
      .get()
      .then((querySnapshot) => querySnapshot.docs.map((data) => data.data()))
  },
  addCouponFlashsaleEvent : (event) => {
    const id = generateUid(10)
    const doc = {...event, id }
    return db
      .collection('couponEvents')
      .doc(id)
      .set(doc)
      .then(() => doc)
  }
}

const getCouponIdFromCode = (code) => {
  return db
    .collection('couponCodes')
    .where('code', '==', code)
    .get()
    .then((querySnapshot) => querySnapshot.docs.map((data) => data.data().couponId))
}

const checkCouponAvailable = (couponId, shopId) => {
  return db
    .collection('coupons')
    .where('id', '==', couponId)
    .where('endedAt', '>', new Date())
    .get()
    .then((data) => {
      if (data.docs.length > 0) {
        const dataShopId = data.docs[0].data().shopId
        if (dataShopId === shopId) {
          return true
        }
      }
      return false
    })
    .catch(() => null)
}

const checkCouponCodeStatus = (code) => {
  return db
    .collection('couponCodes')
    .where('code', '==', code)
    .get()
    .then((checkStatusRedeem) => {
      if (checkStatusRedeem.docs.length > 0) {
        const { status } = checkStatusRedeem.docs[0].data()
        if (status === CouponStatus.REDEEMED) {
          return true
        }
      }
      return false
    })
    .catch(() => null)
}

const checkCouponCodeRedeem = (code) => {
  return db
    .collection('couponCodes')
    .where('code', '==', code)
    .where('status', '==', CouponStatus.CLAIMED)
    .get()
    .then((checkdataRedeem) => {
      const result = []
      checkdataRedeem.forEach((doc) => {
        result.push({
          ...doc.data(),
          status: CouponStatus.REDEEMED,
        })
      })
      return result
    })
}
// ===================== COUPON =====================

// TODO: need to refactored
export const getListCodeShopReport = (ListVoucher) => {
  return db.collection('codes').where('status', '==', CouponStatus.REDEEMED).where('voucherId', 'in', ListVoucher)
}

export const getShop = (shopId) => {
  return db
    .collection('shops')
    .where('id', '==', shopId)
    .get()
    .then((data) => {
      const result = []
      data.forEach((doc) => {
        result.push(doc.data())
      })
      return result.length > 0 ? result[0] : null
    })
}

export const getAllVoucherId = () => {
  return db
    .collection('vouchers')
    .get()
    .then((data) => {
      const result = []
      data.forEach((doc) => {
        result.push(doc.data())
      })
      return result
    })
}

export const getAllVoucher = () => {
  return db.collection('codes').where('status', 'in', [CouponStatus.CLAIMED, CouponStatus.REDEEMED])
}

export const getAllShop = () => {
  return db
    .collection('shops')
    .get()
    .then((data) => {
      const result = []
      data.forEach((doc) => {
        result.push(doc.data())
      })
      return result
    })
}

export const filterDateTable = (startDate, endDate) => {
  return db.collection('codes').where('redeemedAt', '>=', startDate).where('redeemedAt', '<=', endDate)
}

export const filterDateTableMerchant = (startDate, endDate, ListVoucher) => {
  return db
    .collection('codes')
    .where('voucherId', 'in', ListVoucher)
    .where('redeemedAt', '<=', endDate)
    .where('redeemedAt', '>=', startDate)
}
