import { ResetCSS } from '@pancakeswap/uikit'
import { MENU_HEIGHT } from 'components/Menu/config'
import PageLoader from 'components/PageLoader'
import { GuardProvider, GuardedRoute } from 'react-router-guards'
import SuspenseWithChunkError from 'components/SuspenseWithChunkError'
import ToastListener from 'components/ToastListener'
import Toolbar from 'components/Toolbar'
import React, { lazy } from 'react'
import { Router, Switch } from 'react-router-dom'
import history from 'routerHistory'
import GlobalStyle from 'style/Global'
import styled from 'styled-components'
import * as FirestoreService from 'utils/firestore'

const Home = lazy(() => import('./views/Home'))
const Merchants = lazy(() => import('./views/Merchants'))
const MerchantsReport = lazy(() => import('./views/Merchants/Report'))
const Admin = lazy(() => import('./views/Admin'))
const AdminReport = lazy(() => import('./views/Admin/Report'))
const NotFound = lazy(() => import('./views/NotFound'))

const Script = lazy(() => import('./views/Script'))

const Root = styled.div`
background: ${({ theme }) => theme.colors.background};
`

const requireLogin = async (to, from, next) => {
  try {
    const user = await FirestoreService.checkAuthen()
    const role = await FirestoreService.getAccountRole(user.uid)
    console.log("role :", role)
    if (to.meta.auth) {
      if (user) {
        if (to.meta.role) {
          if (to.meta.role === role) {
            next()
          } else {
            next.redirect('/')
          }
        } else {
          next()
        }
      }
      next.redirect('/') // login
    } else {
      next()
    }
  } catch (_) {
    next.redirect('/')
  }
}

const App: React.FC = () => {
  return (
    <Root>
      <ResetCSS />
      <GlobalStyle />
      <Router history={history}>
        <SuspenseWithChunkError fallback={<PageLoader />}>
          <GuardProvider guards={[requireLogin]} loading={PageLoader} error={NotFound}>
            <Switch>
              <GuardedRoute path="/" exact component={Home} />
              {/* <GuardedRoute
                path="/admin"
                exact
                component={Admin}
                meta={{ auth: true, role: FirestoreService.Role.ADMIN }}
              />
              <GuardedRoute
                path="/admin/report"
                exact
                component={AdminReport}
                meta={{ auth: true, role: FirestoreService.Role.ADMIN }}
              /> 
              <GuardedRoute
                path="/admin/script"
                exact
                component={Script}
                meta={{ auth: true, role: FirestoreService.Role.ADMIN }}
              /> */}
              <GuardedRoute path="/checkin" exact component={Merchants} meta={{ auth: true }} />
              {/* <GuardedRoute path="/shop/report" exact component={MerchantsReport} meta={{ auth: true, role: FirestoreService.Role.MERCHANT }} /> */}
              <GuardedRoute path="*" component={NotFound} />
            </Switch>
          </GuardProvider>
        </SuspenseWithChunkError>
        <ToastListener />
      </Router>
    </Root>
  )
}

export default React.memo(App)
